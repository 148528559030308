$(document).ready(function() {
	$('.burger').click(() => {
		$('.nav--main').toggleClass('open');
		$('.menu').toggleClass('open');
        $('menu--link').toggleClass('wow fadeIn');
        $('html, body').toggleClass('locked');
	});

	$(document).scroll(function () {
        var scroll = $(this).scrollTop();
        var topDist = $(".nav--main").position();
        if (scroll > topDist.top) {
            $('.nav--main').addClass('scrolled');
        } else {
            $('.nav--main').removeClass('scrolled');
        }
    });

    var $grid = $('.grid').isotope({
      itemSelector: '.cs',
      layoutMode: 'fitRows'
    });

    $('.filter-button-group').on( 'click', 'button', function() {
      var filterValue = $(this).attr('data-filter');
      $('.angle-button').removeClass('active');
      $(this).parent().addClass('active');
      $grid.isotope({ filter: filterValue });
    });

    $('.slick').slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      arrows: false,
      autoplay: true,
      cssEase: 'linear'
    });

    $('[data-fancybox="gallery"]').fancybox({
      loop: true,
      infobar: false
    });

    $('.map-toggle').click(function() {
      $('.map').toggleClass('visible');
      $('.map-toggle').removeAttr('disabled');
      $(this).attr('disabled', true);
    })
})

